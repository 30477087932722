<template>
    <v-sheet class="editor-inline">
        <div
            class="editor-field"
            style="display:flex"
        >
            <input
                v-if="!meta.readOnly"
                v-model="currentTag"
                type="text"
                class="field-control pl-2 pr-2"
                outlined
                single
                flat
                @keydown="editorKeyDown"
                @blur="resetErrors"
                @focus="openMenu"
            >
            <v-btn
                v-if="!meta.readOnly"
                x-small
                icon
                class="ml-2"
                color="primary"
                @click="saveTag"
            >
                <v-icon>add</v-icon>
            </v-btn>
            <v-sheet
                v-if="error"
                class="error--text floating-error pt-1 pb-1 pr-2 pl-2"
                elevation="10"
                @click="resetErrors"
            >
                <span v-safe-html="errorMessage" />
                <v-btn
                    icon
                    x-small
                    style="float: right;"
                >
                    <v-icon small>
                        close
                    </v-icon>
                </v-btn>
            </v-sheet>
            <div
                v-if="optionsOpen"
                class="dd"
            >
                <v-card elevation="5">
                    <v-list>
                        <v-list-item
                            v-for="option in availableOptions"
                            :key="`tag-item-${option}`"
                            @click="addTag(option)"
                        >
                            <v-list-item-title>
                                {{ option }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </div>
        <v-chip
            v-for="tag in editor_value"
            :key="'tag-chip-' + tag"
            small
            close
            class="ma-1"
            @click:close="removeTag(tag)"
        >
            {{ tag }}
        </v-chip>
    </v-sheet>
</template>

<script>
    export default {
        name: 'Tags',
        props: ['value', 'open', 'meta'],
        data() {
            return {
                editor_value: this.value,
                currentTag: '',
                error: false,
                errorMessage: '',
                menuOpen: false,
            };
        },
        computed: {
            availableOptions() {
                const options = this.meta.availableOptions || [];
                return options.filter(s => this.editor_value.indexOf(s) === -1);
            },
            hasOptions() {
                return this.availableOptions.length > 0;
            },
            optionsOpen() {
                return this.hasOptions && this.menuOpen;
            }
        },
        methods: {
            addTag(tag) {
                this.menuOpen = false;
                if (tag === '') {
                    this.errorMessage = 'Can\'t add an empty item';
                    this.error = true;
                    return;
                }
                if (this.editor_value.indexOf(tag) > -1) {
                    this.errorMessage = 'Can\'t add an item twice';
                    this.error = true;
                    return;
                }
                this.editor_value.push(tag);
                this.currentTag = '';
                this.editDone();
            },
            removeTag(tag) {
                if (this.meta.readOnly) {
                    return;
                }
                this.menuOpen = false;
                const index = this.editor_value.indexOf(tag);
                this.editor_value.splice(index, 1);
                this.editDone();
            },
            resetErrors() {
                setTimeout(() => this.menuOpen = false, 200);
                this.error = false;
                this.errorMessage = '';
            },
            editDone() {
                this.resetErrors();
                this.$emit('input', this.editor_value);
            },
            editCancelled() {
                this.resetErrors();
                this.$emit('cancel');
            },
            saveTag() {
                this.addTag(this.currentTag);
            },
            editorKeyDown(e) {
                this.resetErrors();
                if (e.keyCode === 27) {
                    this.currentTag = '';
                    return;
                }

                if (e.keyCode === 13) {
                    this.saveTag();
                    return;
                }

            },
            openMenu() {
                this.menuOpen = true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .floating-error {
        top: 25px !important;

        &:after, &:before {
            display: none;
        }
    }

    .dd {
        top: 25px;
        position: absolute;
        z-index: 9
    }

</style>

import Vuex from 'vuex';
import Vue from 'vue';
import layout from './layout-store';
import auth from './auth-store';
import libraries from './library-store';
import roles from './role-store';
import interactive from './interactive-store';
import presentation from './presentation-store';
import ftp from './ftp-store';
import statisticsDetails from './statistics-details-store.js';
import mediaLibraryStore from './pagination/media-library-store.js';
import companyStore from './pagination/company-store.js';
import libraryStore from './pagination/library-store.js';
import userStore from './pagination/user-store.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: process.env.VUE_APP_VUEX_STRICT_MODE,
    modules: {
        layout: layout,
        auth: auth,
        libraries: libraries,
        roles: roles,
        interactive: interactive,
        presentation: presentation,        
        ftp: ftp,
        'statistics-details': statisticsDetails,
        'media-library-store': mediaLibraryStore,
        'company-store': companyStore,
        'library-store': libraryStore,
        'user-store': userStore,
    },
});

export default store;

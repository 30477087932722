<template>
    <div>
        <v-container
            id="upload-page"
            fluid
            fill-height
        >
            <v-row
                justify-center
                align-center
            >
                <v-col
                    ref="dragDrop"
                    class="drop-area darken-1 mx-3"
                    :class="{'drag-active': dragActive}"
                >
                    <div class="content pl-3 pr-3">
                        <h1 class="page-header mb-3">
                            Upload
                        </h1>
                        <v-col
                            sm="12"
                            class="drag-and-drop draggable text-center pa-0"
                        >
                            <v-card
                                elevation="0"
                                color="transparent"
                                class="drop-vcard"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="4"
                                        lg="4"
                                        offset="0"
                                        offset-md="4"
                                        offset-lg="4"
                                    >
                                        <v-autocomplete
                                            v-if="libraries.length > 1"
                                            ref="librarySelector"
                                            v-model="library"
                                            class="drop-selector"
                                            solo
                                            outlined
                                            flat
                                            label="Select the library you'd like to upload to"
                                            :items="libraries"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            :filter="customSelectionFilter"
                                            clearable
                                            :menu-props="elementMenuProps"
                                        >
                                            <template v-slot:selection="data">
                                                <v-sheet
                                                    class="selection-content"
                                                    color="transparent"
                                                >
                                                    <span class="library-sheet-content">
                                                        <span class="library-company-name">{{ getLibraryCompanyName(data.item) }}</span>
                                                        <span class="library-name">{{ data.item.name }}</span>
                                                    </span>
                                                </v-sheet>
                                            </template>
                                            <template v-slot:item="{item}">
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ getLibraryCompanyName(item) }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <div :hidden="uploadIsHidden">
                                    <v-icon
                                        color="primary"
                                        class="upload-icon"
                                    >
                                        mdi-cloud-upload
                                    </v-icon>
                                    <br>
                                    <h1 class="drop-caption darkgray--text">
                                        Drag & drop files or folder
                                        <small class="d-block font-italic grey--text">Maximum file size: 10 GB</small>
                                    </h1>
                                    <file-upload
                                        ref="upload"
                                        v-model="files"
                                        :thread="5"
                                        accept="video/mov,video/quicktime,video/mp4,video/x-m4v,video/*"
                                        :multiple="true"
                                        :chunk-enabled="true"
                                        :chunk="chunk"
                                        drop="#upload-page .drop-area"
                                        :drop-directory="true"
                                        class="py-2 px-5 draggable"
                                        :timeout="120000"
                                        @input-filter="inputFilter"
                                        @input-file="onFileInput"
                                        @input="$refs.upload.active = true"
                                    >
                                        <v-btn
                                            color="primary"
                                            class="my-5 py-0"
                                        >
                                            Or choose files
                                        </v-btn>
                                    </file-upload>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col
                            sm="12"
                            xl="8"
                            offset-xl="2"
                            lg="10"
                            offset-lg="1"
                            class="pa-0"
                        >
                            <v-card
                                elevation="0"
                                color="transparent"
                                class="upload-list"
                                style=""
                            >
                                <transition-group
                                    name="file-list"
                                    tag="div"
                                >
                                    <v-row
                                        v-for="(file, index) in filteredFiles"
                                        :key="file.id"
                                        class="file-row pl-8 pr-8"
                                    >
                                        <v-row
                                            class="mr-4 ml-4 list-control-item"
                                            :class="{'no-errors': !file.error || file.error == 'cancel'}"
                                            @click.stop="onSelectionChange(file)"
                                        >
                                            <v-card
                                                elevation="0"
                                                class="list-mode d-flex pl-2 pr-2 pt-0 pb-1"
                                                style="width: 100%"
                                            >
                                                <div class="toggle-column flex-grow-0 pa-2">
                                                    <v-checkbox
                                                        v-if="file.success"
                                                        :input-value="file.selected"
                                                        @click.stop="onSelectionChange(file)"
                                                    />
                                                </div>
                                                <v-col
                                                    style="flex: 0 0 0"
                                                    class="pr-0 pl-0"
                                                >
                                                    <v-icon
                                                        color="darkGray"
                                                        class="video-icon"
                                                        size="48"
                                                    >
                                                        mdi-file-video
                                                    </v-icon>
                                                </v-col>
                                                <v-col
                                                    class="pb-0 auto-height break-all"
                                                    style="flex: 1 1 0; line-height: normal"
                                                >
                                                    <v-row>
                                                        <v-col
                                                            sm="12"
                                                            md="9"
                                                            class="pa-0"
                                                        >
                                                            <div class="file-info-holder pl-5 d-inline-block">
                                                                {{ file.name }} ({{ file.size | filesize }})
                                                                <span
                                                                    v-if="$vuetify.breakpoint.mdAndUp"
                                                                    class="item-library"
                                                                >in: {{ getLibraryCompanyName(getFileLibrary(file)) }} / {{ getLibraryName(getFileLibrary(file)) }}</span>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            sm="12"
                                                            md="3"
                                                            class="pt-0 pl-0 pb-0 pr-8 d-flex progress-data"
                                                        >
                                                            <span>{{ Math.ceil(file.progress || 0) }} % <v-icon
                                                                v-if="file.success"
                                                                size="18"
                                                                color="success"
                                                            >
                                                                mdi_check
                                                            </v-icon></span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col
                                                            sm="12"
                                                            md="12"
                                                            lg="12"
                                                            xl="12"
                                                        >
                                                            <v-progress-linear
                                                                :bottom="true"
                                                                class="elevation-0"
                                                                :value="file.progress"
                                                                rounded
                                                                :color="file.success ? 'primary' : (file.error ? (file.error == 'cancel' ? 'warning' : 'error') : 'primary')"
                                                                height="10"
                                                            />
                                                            <v-sheet
                                                                v-if="file.error && file.error != 'cancel'"
                                                                v-safe-html="getErrors(file)"
                                                                color="transparent"
                                                                class="file-errors"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col
                                                    style="align-items: center"
                                                    class="text-right pt-2 pr-0 pl-0 d-flex flex-grow-0 flex-shrink-0"
                                                >
                                                    <v-btn
                                                        v-if="file.active || file.error || (file.progress == 0 && !file.error)"
                                                        icon
                                                        color="red darken-1 white--text"
                                                        @click.prevent="cancelUpload(file)"
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="file.error && file.error != 'cancel' && $refs.upload.features.html5"
                                                        icon
                                                        color="secondary"
                                                        @click.prevent="retryUpload(file)"
                                                    >
                                                        <v-icon>mdi-reload</v-icon>
                                                    </v-btn>
                                                    <router-link
                                                        v-if="file.success"
                                                        :to="{name: 'mediaLibrary', 'query': {videoId: file.objectId}}"
                                                        style="text-decoration: none"
                                                        target="_blank"
                                                    >
                                                        <v-btn
                                                            text
                                                            class="mr-2"
                                                            elevation="0"
                                                            outlined
                                                            color="rgb(85,85,85)"
                                                        >
                                                            Go to video
                                                        </v-btn>
                                                    </router-link>
                                                    <v-btn
                                                        v-show="file.success"
                                                        icon
                                                        color="error"
                                                        :disabled="file.active"
                                                        @click="removeFile(index, file)"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-card>
                                        </v-row>
                                    </v-row>
                                </transition-group>
                            </v-card>
                        </v-col>
                    </div>
                </v-col>
            </v-row>
            <delete-videos
                v-model="deleteModal"
                :videos="deleteVideos"
                @confirm="confirmDelete"
            />
        </v-container>
        <right-drawer v-if="hasSelectedVideo">
            <upload-metadata :selected-video="selectedVideo" />
        </right-drawer>
        <confirm-leave-upload ref="confirmLeaveUpload" />
        <upload-files-with-errors
            v-model="uploadErrorModalOpen"
            :files="uploadErrors"
        />
        <confirm-cancel-upload
            ref="confirmCancelUpload"
            :upload-item="itemToCancel"
        />
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import VueUploadComponent from 'vue-upload-component';
    import {ChunkUploadHandler} from '../../plugins/chunk-upload/ChunkUploadHandler';
    import DeleteVideos from '../controls/modals/delete-videos';
    import {$http, videoApiUrl} from '../../services/http';
    import {toastError, toastSuccess} from '../../services/responseErrors';
    import {getProperUnitSize} from '../../services/metadata';
    import RightDrawer from '../controls/right-drawer';
    import UploadMetadata from '../controls/video-metadata/upload-metadata';
    import ConfirmLeaveUpload from '@/components/controls/modals/confirm-leave-upload';
    import UploadFilesWithErrors from '@/components/controls/modals/upload-files-with-errors';
    import getFileError from '@/plugins/chunk-upload/utils/uploadFileErrors';
    import ConfirmCancelUpload from '@/components/controls/modals/confirm-cancel-upload';

    // 2MB per chunk
    const CHUNK_DEFAULT_OPTIONS = {
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
        },
        action: process.env.VUE_APP_VIDEO_API_HOST + 'api/upload/chunk',
        minSize: 1048576,
        maxActive: 3,
        maxRetries: 5,
        handler: ChunkUploadHandler
    };

    export default {
        name: 'UploadPage',
        components: {
            ConfirmCancelUpload,
            UploadFilesWithErrors,
            ConfirmLeaveUpload,
            FileUpload: VueUploadComponent,
            DeleteVideos,
            RightDrawer,
            UploadMetadata
        },
        filters: {
            filesize: function (num) {
                if (typeof num !== 'number' || isNaN(num)) {
                    return 0;
                }
                return getProperUnitSize(num);
            }
        },
        data: function () {
            return {
                files: [],
                dragActive: false,
                feedback: '',
                snackBarColor: 'error',
                snackbar: false,
                deleteVideos: [],
                deleteModal: false,
                library: null,
                selectedVideo: null,
                libraries: [],
                elementMenuProps: undefined,
                itemToCancel: {}
            };
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('layout', ['uploadErrors', 'uploadErrorModal']),
            ...mapGetters('libraries', ['getLibraryCompanyName']),
            extraData() {
                return {
                    library: this.library.id
                };
            },
            uploadIsHidden() {
                return !this.library;

            },
            chunk() {
                // needs to reload the access token as when CHUNK_DEFAULT_OPTIONS is defined,
                // local storage may not be filled/available
                return {
                    ...CHUNK_DEFAULT_OPTIONS,
                    headers: {
                        ...CHUNK_DEFAULT_OPTIONS.headers,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
                    }
                };
            },
            filteredFiles() {
                return this.files.filter((file) => {
                    return !file.error || file.error != 'cancel';
                });
            },
            hasFilesInProgress() {
                return this.files.filter(f => f.progress < 100 && !f.error).length > 0;
            },
            hasSelectedVideo() {
                return this.selectedVideo !== null;
            },
            uploadErrorModalOpen: {
                get() {
                    return this.uploadErrorModal;
                },
                set(value) {
                    this.setUploadErrorModal(value);
                }
            },
        },
        watch: {
            $route(to) {
                if (to.name == 'upload') {
                    this.setElementMenuProps();
                    this.library = null;
                    this.$store.dispatch('libraries/getLibraries');
                }
            },
        },
        methods: {
            ...mapMutations('layout', ['setUploadErrorModal']),
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!this.library) {
                        if (this.libraries.length === 1) {
                            this.setLibrary(this.libraries[0]);
                        } else {
                            toastError('Please select a library before upload.');
                            return prevent();
                        }
                    }

                    if (!/^video\/.*$/i.test(newFile.type)) {
                        var fileExtension = newFile.name.substring(newFile.name.lastIndexOf('.') + 1, newFile.name.length);
                        toastError('File: ' + newFile.name + ' with type of ' + fileExtension + ' not accepted!');
                        return prevent();
                    }
                    // do not allow files smaller then the minimum chunk
                    if (newFile.size < CHUNK_DEFAULT_OPTIONS.minSize) {
                        toastError('File: ' + newFile.name + ' is not accepted! The minimum filesize is 1MB');
                        return prevent();
                    }
                    // prevent uploading very large files
                    if (newFile.size > process.env.VUE_APP_MAXIMUM_FILE_SIZE) {
                        toastError('File: ' + newFile.name + ' is too large. The maximum file size that can be uploaded is 10 GB.');
                        return prevent();
                    }

                    // inputFilter is called both both at start and finish (which is odd)
                    // - this check makes sure that the library selected for a file already uploading is not changed
                    // - allows selecting a different library for a subsequent upload
                    if (!newFile.data.library) {
                        newFile.data.library = this.extraData.library;
                    }

                    newFile.data.uploaded_by = this.user.name;
                }
            },
            removeFile(index, file) {
                this.deleteVideos = [
                    {
                        id: file.objectId,
                        title: file.name,
                        uploadFileObject: file
                    }
                ];

                this.deleteModal = true;
            },
            confirmDelete(items) {
                this.deleteItems(items).then(items => {
                    for (let i in items) {
                        this.$refs.upload.remove(items[i].uploadFileObject);
                    }
                });
            },
            deleteItems(items) {
                return new Promise((resolve, reject) => {
                    let requests = items.map(function (item) {
                        return new Promise((resolve, reject) => {
                            $http.delete(videoApiUrl(`api/videos/${item.id}`)).then(() => {
                                resolve();
                            }).catch((e) => reject(e));
                        });
                    });

                    Promise.all(requests).then(() => {
                        toastSuccess('Videos deleted');
                        resolve(items);
                    }).catch(e => {
                        reject(e);
                    });
                });
            },
            async cancelUpload(file) {
                this.itemToCancel = file;
                const confirmation = await this.$refs.confirmCancelUpload.open();
                if (!confirmation) {
                    return;
                }

                if (file.chunk && file.chunk.file && file.chunk.chunks) {
                    await file.chunk.abort().catch(r => {
                        console.error(r);
                    });
                }
                this.$refs.upload.update(file, {error: 'cancel'});
                if (!file.error) {
                    //decrease uploading counter to make sure uploads dont get stuck
                    // decrease only if there are no errors (otherwise it will screw up the uploader, weird...)
                    this.$refs.upload.uploading--;
                }
            },
            retryUpload(file) {
                this.$refs.upload.update(file, {active: true, error: '', progress: '0.00'});
            },
            onDragLeave(e) {
                if (e.target.className.indexOf('drop-area') !== -1 &&
                    e.fromElement.parentNode.className.indexOf('drop-area') === -1) {
                    this.dragActive = false;
                }
            },
            onDrop() {
                this.dragActive = false;
            },
            onDragEnter(e) {
                if (e.target.className !== 'row') {
                    this.dragActive = true;
                }
            },
            onBodyDropOrDragOver(e) {
                e.preventDefault();
                e.stopPropagation();
                if (event.type !== 'drop') {
                    return false;
                }
            },
            setLibrary(library) {
                this.library = library;
            },
            getFileLibrary(file) {
                if (file.data.library) {
                    const items = this.libraries.filter(item => item.id === file.data.library);
                    return items.length > 0 ? items[0] : null;
                }
            },
            getLibraryName(library) {
                if (!library) {
                    return '';
                }

                return library.name;
            },
            getErrors(file) {
                let s = '';
                if (file.response?.errors) {
                    s = Object.values(file.response.errors)
                        .reduce((a, b) => Object.values(a).join('<br />') + '<br />' + Object.values(b).join('<br />'));

                    if (Array.isArray(s)) {
                        return s.join('<br />');
                    }
                } else {
                    s = getFileError(file);
                }
                return s;
                // return "";
            },
            onSelectionChange(file) {
                if (!file.success) {
                    return;
                }
                this.filteredFiles.forEach(item => item.selected = false);
                if (this.selectedVideo !== file) {
                    this.selectedVideo = file;
                    file.selected = true;
                } else {
                    this.selectedVideo = null;
                    file.selected = false;
                }
            },
            customSelectionFilter(item, queryText) {
                const itemName = item.name.toLowerCase();
                const itemCompanyName = (item.company?.company_name || '').toLowerCase();
                const searchText = queryText.toLowerCase();
                return itemName.includes(searchText) || itemCompanyName.includes(searchText);
            },
            onFileInput(newFile) {
                if(newFile && !this.selectedVideo) {
                    this.onSelectionChange(newFile);
                }
            },
            async getLibraries() {
                const libs = await $http.get(videoApiUrl('api/libraries/for-upload'));
                this.libraries = libs.data;
            },
            checkClose(e) {
                const message = 'You are trying to exit this screen. Any unfinished upload(s) will be lost. Are you sure?';
                if (this.hasFilesInProgress) {
                    e.preventDefault();
                    e.returnValue = message;
                    return message;
                } else {
                    delete e['returnValue'];
                    return true;
                }
            },
            windowResize() {
                this.setElementMenuProps();
            },
            setElementMenuProps() {
                if (this.$refs.librarySelector) {
                    this.$refs.librarySelector.blur();
                    const element = this.$refs.librarySelector.$el;
                    const style = getComputedStyle(element);
                    const dropDownWidth = style.width.replace('px', '');
                    this.elementMenuProps = {'max-width': dropDownWidth, contentClass: 'should-wrap'};
                }
            }
        },
        async mounted() {
            this.library = null;
            await this.getLibraries();
            if (this.libraries.length === 1) {
                this.library = this.libraries[0];
            }
            this.$refs.dragDrop.parentNode.addEventListener('dragleave', this.onDragLeave, false);
            this.$refs.dragDrop.addEventListener('drop', this.onDrop);
            this.$refs.dragDrop.addEventListener('dragenter', this.onDragEnter, false);
            document.addEventListener('drop', this.onBodyDropOrDragOver, false);
            document.addEventListener('dragover', this.onBodyDropOrDragOver, false);
            window.addEventListener('beforeunload', this.checkClose);
            window.addEventListener('resize', this.windowResize);
            this.setElementMenuProps();
        },
        beforeDestroy() {
            this.$refs.dragDrop.parentNode.removeEventListener('dragleave', this.onDragLeave);
            this.$refs.dragDrop.removeEventListener('dragenter', this.onDragEnter);
            this.$refs.dragDrop.removeEventListener('drop', this.onDrop);
            document.removeEventListener('drop', this.onBodyDropOrDragOver);
            document.addEventListener('dragover', this.onBodyDropOrDragOver, false);
            window.removeEventListener('beforeunload', this.checkClose);
            window.removeEventListener('resize', this.windowResize);
        },
        deactivated() {
            this.selectedVideo = null;
            this.files = [];
        },
        async beforeRouteLeave(to, from, next) {
            this.setElementMenuProps();
            if (this.hasFilesInProgress) {
                const result = await this.$refs.confirmLeaveUpload.open();
                next(result);
            } else {
                next();
            }
        },
    };
</script>

<style lang="scss">
    #upload-page {
        .drop-area {
            &.drag-active {
                border: 4px solid #2977be !important;
                background-color: lighten(#2977be, 50%) !important;
                padding: 8px;
            }

            .drop-caption {
                font-weight: normal;
                font-size: 1.2rem;
            }
        }

    }

    .drag-and-drop {
        .upload-icon {
            font-size: 80px;
        }

        .file-uploads {
            label {
                cursor: pointer;
            }
        }
    }

    .file-row {
        .video-icon {
            font-size: 80px;
        }
    }

    .file-list-item {
        display: block;
    }

    .file-list-enter-active, .file-list-leave-active {
        transition: all 1s;
    }

    .file-list-enter {
        opacity: 0;
        transform: translateX(30px);
    }

    .file-list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .drop-vcard {
        z-index: 1;
        border: 0 none transparent;
        background-color: transparent;
    }

    .progress-data {
        align-content: flex-end;

        span {
            flex: 1 1 0;
            text-align: right;
        }

        .v-icon {
            flex: 0 0 16px;
            width: 16px;
            text-indent: -60px;
            font-weight: bold;
        }
    }

    .library-sheet-content {
        text-align: center;
        margin-left: 25px;
    }

    .library-company-name {
        opacity: .7;
        margin-right: .5rem
    }

    .library-company-name:after {
        content: "/";
        margin-left: .5rem;
    }

    .selection-content {
        width: 100%;
        text-align: center;
    }

    .drop-selector .v-select__slot label {
        display: block;
        width: 100%;
        text-align: center;
        padding-left: 50px;
    }

    .item-library {
        opacity: .5;
        margin-left: 1rem;
        font-size: .7rem;
    }

    .file-errors {
        padding: 5px 7px;
        color: red !important;
        font-size: .75rem;
        background-color: transparent;
    }

    .upload-list .list-control-item .v-card.list-mode > div {
        height: auto !important;
    }

    .upload-list .list-control-item.no-errors .v-card.list-mode > div.auto-height {
        height: auto !important;
    }

    .break-all {
        word-break: break-all;
    }

    .should-wrap {
      .v-list-item__title {
        white-space: initial;
      }
    }
</style>

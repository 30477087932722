<template>
    <div>
        <v-container
            id="list-container"
            class="pt-0"
            fluid
            fill-height
        >
            <v-row
                justify-center
                align-center
                no-gutters
            >
                <v-col>
                    <div
                        class="content"
                        :class="layoutGrid"
                    >
                        <v-col class="pt-0">
                            <media-library-list-control
                                @selection-change="onSelectionChange"
                                @sorted="onSorted"
                            >
                                <template v-slot:title>
                                    <h1 class="ml-4 mr-4 page-header">
                                        Media Library
                                    </h1>
                                </template>
                            </media-library-list-control>
                        </v-col>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <right-drawer v-if="hasSelectedVideos">
            <video-metadata :videos="selectedVideos" />
        </right-drawer>
    </div>
</template>

<script>
    import MediaLibraryListControl from '../controls/media-library-list-control';
    import RightDrawer from '../controls/right-drawer';
    import VideoMetadata from '../controls/video-metadata/video-metadata';
    import {mapState} from 'vuex';

    export default {
        components: {
            MediaLibraryListControl,
            RightDrawer,
            VideoMetadata
        },
        data () {
            return {
                selectedVideos: [],
                updated: 1
            };
        },
        computed: {
            ...mapState('layout', ['gridMode']),
            layoutGrid() {
                return this.gridMode === 1 ? ['grid-style'] : [];
            },
            hasSelectedVideos() {
                return this.selectedVideos.length > 0;
            }
        },
        methods: {
            onSelectionChange(selectedItems) {
                this.selectedVideos = selectedItems;
                this.updated++;
            },
            onSorted() {
                this.selectedVideos = [];
                this.updated++;
            }
        },
    };
</script>

<template>
    <div class="pb-1">
        <div class="d-flex">
            <div class="component-title">
                <span>
                    <slot name="title">title</slot>
                </span>
            </div>
            <div
                v-if="canEdit"
                class="default-title"
            >
                <span>
                    Show default
                </span>
            </div>
            <div class="component-action">
                <input
                    v-show="false"
                    ref="fileInput"
                    type="file"
                    accept=".srt,.vtt,application/x-subrip,application/subrip,text/srt,text/x-srt,text/vtt"
                    @change="uploadSelectedFiles"
                >
                <div v-if="canEdit">
                    <v-progress-circular
                        v-if="fileUploading"
                        indeterminate
                    />
                    <v-tooltip
                        v-else
                        top
                    >
                        <template v-slot:activator="{on}">
                            <v-btn
                                icon
                                x-small
                                class="my-0 py-0 ml-2"
                                color="primary"
                                v-on="on"
                                @click="selectFile"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </template>
                        <span>Upload subtitle</span>
                    </v-tooltip>
                </div>
            </div>
        </div>
        <v-list
            v-if="items.length > 0"
            dense
            flat
            class="tiny-actions"
        >
            <v-list-item-group>
                <v-list-item
                    v-for="item in items"
                    :key="item.id"
                    class="pa-0"
                >
                    <v-list-item-content
                        class="wrap-any pa-0"
                        @click="download(item)"
                    >
                        {{ item.name }}
                    </v-list-item-content>
                    <v-list-item-action
                        v-if="canEdit"
                        class="ma-0 pr-8"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    @click="setDefault(item)"
                                >
                                    <v-icon
                                        v-if="item.default"
                                        color="black"
                                    >
                                        check_box
                                    </v-icon>
                                    <v-icon v-else>
                                        check_box_outline_blank
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span><span v-if="!item.default">Set&nbsp;</span>Default</span>
                        </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action
                        v-if="canEdit"
                        class="ma-0"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    icon
                                    small
                                    :disabled="item.default"
                                    v-on="on"
                                    @click="deleteItem(item)"
                                >
                                    <v-icon color="error">
                                        delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <confirm-modal
            v-model="deleteModal"
            title="Delete Subtitle"
            confirm-button="Delete subtitle"
            cancel-button="Cancel"
            @confirm="confirmDelete"
        >
            <p>Would you like to delete the following subtitle?</p>
            {{ selectedItem.name }}
        </confirm-modal>
    </div>
</template>

<script>
    import {$http, videoApiUrl} from '../../../../services/http';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';
    import ConfirmModal from '../../modals/confirm-modal';

    export default {
        name: 'Subtitles',
        components: {ConfirmModal},
        props: ['video'],
        data() {
            return {
                items: [],
                listLoading: false,
                fileUploading: false,
                selectedItem: {},
                deleteModal: false,
                setDefaultModal: false,
                isDestroying: false,
            };
        },
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            getItems() {
                // don't send request if the user clicks back and forth between videos
                // (prevents sending the request twice/in vain)
                if (this.isDestroying) {
                    return;
                }
                this.listLoading = true;
                $http.get(videoApiUrl(`api/videos/${this.video.id}/subtitles`)).then(response => {
                    this.items = response.data;
                }).finally(() => {
                    this.listLoading = false;
                });
            },
            deleteItem(item) {
                this.selectedItem = item;
                this.deleteModal = true;
            },
            confirmDelete() {
                $http.delete(videoApiUrl(`api/videos/${this.video.id}/subtitles/${this.selectedItem.id}`)).finally(() => {
                    this.getItems();
                });
            },
            setDefault(item) {
                item.default = !item.default;
                $http.put(videoApiUrl(`api/videos/${this.video.id}/subtitles/${item.id}`), item).then(() => {
                    this.getItems();
                });
            },
            selectFile() {
                this.$refs.fileInput.click();
            },
            async uploadSelectedFiles() {
                this.fileUploading = true;
                const uploads = [];
                for (let i = 0; i < this.$refs.fileInput.files.length; ++i) {
                    const formData = new FormData();
                    formData.append('file', this.$refs.fileInput.files[i]);
                    uploads.push(this._upload(formData));
                }

                await Promise.all(uploads.map(p => p.catch(e => e)));

                this.fileUploading = false;
                this.$refs.fileInput.value = '';
                this.getItems();
            },
            _upload(formData) {
                return $http.post(videoApiUrl(`api/videos/${this.video.id}/subtitles`), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            },
            download(item) {
                const anchor = document.createElement('a');
                anchor.href = item.url;
                anchor.click();
            }
        },
        mounted() {
            setTimeout(this.getItems, 500);
        },
        beforeDestroy()
        {
            this.isDestroying = true;
        }
    };
</script>

<style scoped>

    .v-list.tiny-actions {
        padding: 0;
    }

    .v-list-item {
        min-height: 1.8rem;
    }

    .v-list-item:hover {
        background-color: rgba(0,0,0,.1);
    }

    .component-title {
        flex: 1 1 auto;
    }

    .default-title {
        flex: 0 0 auto;
    }

    .component-title, .default-title {
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    .component-action {
        flex: 0 0 0;
    }

    .wrap-any {
        word-break: break-word;
    }

    .tiny-actions .v-list-item__action {
        min-width: auto !important;
    }
</style>

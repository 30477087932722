import { debounce } from 'lodash';
import { getImageDimensions } from './helpers';
import { $http, videoApiUrl } from '../../services/http';
import InteractiveElement from '../../models/interactive/InteractiveElement';
import UndoneAction from '../../models/undo/UndoneAction';
import { v4 as uuidv4 } from 'uuid';

const interactiveActions = {
    async updateDefaultBackgroundImage({ dispatch, commit }, image) {
        const { url } = image;
        const commits = [
            {
                name: 'setDefaultBackground',
                payload: image
            }
        ];

        if (url) {
            const { width, height } = await getImageDimensions(url);

            commits.push({
                name: 'setStyleDimensions',
                payload: {
                    width,
                    height
                }
            });
        }

        commit('setImageShouldPreserveAspectRatio', false);
        await dispatch('executeWithUndoAndSave', {
            commit: commits
        });
        commit('setImageShouldPreserveAspectRatio', true);
    },
    addElement: ({ commit, getters }, element) => {
        return $http.post(videoApiUrl(`/api/videos/${getters.videoId}/interactive`), JSON.stringify(element), {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(() => {
            commit('addElement', element);
            commit('setActiveElement', element);
        });

    },

    getElements: ({ commit }, videoId) => {
        commit('setLoading', true);

        return $http.get(videoApiUrl(`/api/videos/${videoId}`)).then((response) => {
            commit('setVideo', response.data);
            $http.get(videoApiUrl(`/api/videos/${videoId}/interactive`)).then(response => {
                commit('clearRedo');
                commit('setElements', response.data.interactive_area.map(item => new InteractiveElement(item)));
            }).finally(() => commit('setLoading', false));
        });
    },

    storeElement: ({ commit, dispatch }, element) => {
        commit('pushUndo', new UndoneAction(element, element, 'deleteElement', 'addElement'));
        dispatch('addElement', element).then(() => {
            commit('clearRedo');
        });
    },

    saveElement: debounce(({ getters }, element) => {
        return $http.put(videoApiUrl('/api/videos/' + getters.videoId + '/interactive/' + element._id),
            JSON.stringify(element),
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    }, 300),

    removeElement({ dispatch, commit }, element) {
        commit('pushUndo', new UndoneAction(element, element, 'addElement', 'deleteElement'));
        return dispatch('deleteElement', element).then(() => {
            commit('clearRedo');
        });
    },

    deleteElement: ({ commit, state, getters }, element) => {
        return $http.delete(videoApiUrl('/api/videos/' + getters.videoId + '/interactive/' + element._id)).then(() => {
            const elements = state.elements.filter(item => item._id !== element._id);
            commit('setElements', elements);
        });
    },

    duplicateElement({ dispatch }, element) {
        const duplicate = JSON.parse(JSON.stringify(element));
        duplicate._id = uuidv4();
        duplicate.name += ' - copy';
        dispatch('storeElement', new InteractiveElement(duplicate));
    },

    executeSingleCommit: ({ dispatch }, commit) => {
        return dispatch('executeWithUndoAndSave', {
            commit: [commit]
        });
    },

    executeWithUndoAndSave: async ({ state, commit, dispatch, getters }, params) => {
        const elements = JSON.stringify(state.elements);
        if (params.commit) {
            for (let commitItem in params.commit) {
                if (Object.prototype.hasOwnProperty.call(params.commit, commitItem)) {
                    commit(params.commit[commitItem].name, params.commit[commitItem].payload);
                }
            }
        }

        await dispatch('saveElement', getters.activeElement);

        const updatedElements = JSON.stringify(state.elements);
        commit('clearRedo');
        commit('pushUndo', new UndoneAction(elements, updatedElements, 'restoreState', 'restoreState'));
    },

    restoreState({ commit, getters, dispatch }, elements) {
        commit('setElements', JSON.parse(elements).map((element => new InteractiveElement(element, element.active))));
        return dispatch('saveElement', getters.activeElement);
    },

    undo({ state, commit, dispatch }) {
        const undoItem = state.undoStack.latest();
        commit('popUndo');
        commit('pushRedo', undoItem);
        dispatch(undoItem.undoAction, undoItem.previousState);
    },
    redo({ state, commit, dispatch }) {
        const redoItem = state.redoStack.latest();
        commit('popRedo');
        commit('pushUndo', redoItem);
        dispatch(redoItem.redoAction, redoItem.newState);
    },
    clearUndoRedo: ({ commit }) => {
        commit('clearRedo');
        commit('clearUndo');
    }
};

export default interactiveActions;
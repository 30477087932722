import { sortBy } from 'lodash';
import { $http, videoApiUrl } from '../../services/http';

const getActiveElement = function (state) {
    return state.elements.find(element => element.active);
};

const hasField = function (relations, key, field) {
    if (!relations[key]) {
        return false;
    }

    return relations[key].indexOf(field) !== -1;
};

const uploadInteractiveImage = async function (videoId, image) {
    if (image == null) {
        return Promise.resolve({ path: null, url: null });
    }
    const formData = new FormData();
    formData.append('file', image);
    let response = await $http.post(
        videoApiUrl(`/api/videos/${videoId}/interactive/image-upload`),
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    return Promise.resolve({ path: response.data.path, url: response.data.url });
};

const getEditorAspectRatio = function () {
    const element = document.querySelector('.player-container');
    if (!element) {
        return 0.5625; // assume 16:9
    }

    const style = window.getComputedStyle(element);

    const width = parseFloat(style.width);
    const height = parseFloat(style.height);


    return width / height;
};

// this is tricky because of the aspect ratio of the player
const getImageDimensions = function (url) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            let imageWidth = image.width;
            let imageHeight = image.height;
            let ratio = imageWidth / imageHeight;
            let width = 20;

            if (imageWidth < imageHeight) {
                width = 5;
            }

            const height = width / ratio * getEditorAspectRatio();

            resolve({
                width,
                height
            });
        };

        image.onerror = () => reject();

        image.src = url;

    });
};

const skipToTimeIndex = function (state, value) {
    state.player.$emit('seek', value);
    state.player.$emit('pause');
};

const sortElements = function (state) {
    state.elements = sortBy(state.elements, ['start']);
    state.activeIndex = state.elements.findIndex(item => item.active);
};

const debounceInterval = 500;

export {
    getActiveElement,
    hasField,
    uploadInteractiveImage,
    getImageDimensions,
    skipToTimeIndex,
    sortElements,
    debounceInterval
};
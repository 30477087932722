import {$http, videoApiUrl} from '../services/http';
const urlString = '/api/companies/{id}/logo';

export function saveLogo(companyId, logoData) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('logo', logoData, logoData.name);

        $http.post(videoApiUrl(urlString.replace('{id}', companyId)), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => resolve(response.data))
            .catch(e => reject(e));
    });
}

export function deleteLogo(companyId) {
    return new Promise((resolve, reject) => {
        $http.delete(videoApiUrl(urlString.replace('{id}', companyId)))
            .then(() => resolve())
            .catch(e => reject(e));
    });
}

<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Statistics</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-simple-table
                dense
                class="property-sheet"
            >
                <template v-slot:default>
                    <div>
                        <v-btn
                            small
                            title="Open detail page"
                            color="primary"
                            :to="{name: 'statistics-details-page', params: {id: video.id}}"
                            target="_blank"
                        >
                            <v-icon
                                small
                                class="mr-2"
                            >
                                mdi-chart-bar
                            </v-icon>Detail page
                        </v-btn>
                    </div>
                    <div
                        v-if="hasInteractive"
                        class="mt-2"
                    >
                        <v-btn
                            small
                            title="Download Interactive Stats"
                            color="primary"
                            @click="downloadInteractiveStats"
                        >
                            <v-icon
                                small
                                class="mr-2"
                            >
                                mdi-download
                            </v-icon>Interactive
                        </v-btn>
                    </div>
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import {$http, statsApiUrl, videoApiUrl} from '../../../services/http';
    import TimeHelper from '../../../time-helper';

    export default {
        name: 'MetadataAnalytics',
        props: ['video'],
        data: () => ({
            editing: false,
            interactiveData: [],
            hasInteractive: false,
            toExport: [],
            exportHeaders: {
                timecode: 'Timecode',
                name: 'Name element',
                clicks: 'Clicks'
            },
            nrOfClicks: []
        }),
        computed: {
            videoId() {
                return this.video ? this.video.id : 'null';
            },
        },
        methods: {
            getInteractiveData() {
                $http.get(videoApiUrl(`/api/videos/${this.video.id}/interactive`)).
                    then(response => {
                        if (response.data.interactive_area.length > 0) {
                            this.hasInteractive = true;
                        }
                        this.interactiveData = response.data.interactive_area;
                        this.getNrOfClicks();
                    });
            },
            async getNrOfClicks() {
                let interactiveElementIds = this.interactiveData.map(i => i._id);
                if(interactiveElementIds.length === 0) {
                    this.nrOfClicks = {};
                    return;
                }
                await $http.post(statsApiUrl('api/videos/interactive-clicks'), {
                    interactiveElementIds: interactiveElementIds,
                }).then((response) => {
                    this.nrOfClicks = response.data;
                });
            },
            async downloadInteractiveStats() {
                await this.getNrOfClicks();
                this.toExport = [this.exportHeaders, ...this.interactiveData.map( s => ({
                    timecode: TimeHelper.secondsToHHmmssS(s.start),
                    name: s.name,
                    clicks: this.nrOfClicks[s._id] ?? 0
                }) )];

                let csvContent = 'data:text/csv;charset=utf-8,'
                    + this.toExport.map(e => `${e.timecode},${e.name},${e.clicks}`).join('\n');

                const element = document.createElement('a');
                element.href = csvContent;
                element.setAttribute('download', this.videoId + '.csv');
                document.querySelector('body').appendChild(element);
                element.click();
                document.querySelector('body').removeChild(element);
            }
        },
        mounted() {
            this.getInteractiveData();
        }
    };
</script>